.bannerHome {
  @apply overflow-hidden;
  @apply relative bg-gleads-oxfordBlue-800 pt-[120px] text-center;
  .bannerImageArea {
    .transparentElement {
      @apply  left-1/2 -top-16 z-10 h-[120%] w-[85%] max-w-[1000px] -translate-x-1/2 sm:-top-16 md:top-0 md:h-full md:w-4/5 lg:w-4/5;
    }
    .imgBanner {
      @apply relative z-10 m-auto h-auto max-w-[271px] sm:max-w-[400px] lg:max-w-[600px] xl:w-full xl:max-w-[653px];
    }
    .iconAnimate {
      @apply relative w-[220%] sm:w-[200%] lg:w-[150%];
      .icon {
        @apply absolute -left-8 top-2 md:-left-14 md:-top-5  lg:-left-20;
      }
    }
  }
  
}
.subjectsImage {
  @apply lg:min-w-[500px] xl:min-w-[600px] 2xl:min-w-[647px] relative lg:absolute bottom-0 flex justify-center lg:justify-start items-center;
}