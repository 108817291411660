.sub_menu{
    box-shadow: 1px -5px 15px -5px rgb(187,187,187,50%) ;
    position: absolute;
    z-index: 50;
    display: flex;
    border-radius: 6px;
    background-color: white;
    color: black;
&::after{
    content: "";
    position: absolute;
    z-index: 0;
    background-color: transparent;
    width: 100%;
    left: 0;
    z-index: 0;
    @apply  lg:h-10 xl:h-8  xl:-top-7 lg:-top-8 
}
&::before{
    content: "";
    transform-origin: 0;
    position: absolute;
    top: -10px;
    left: 44px;
    z-index: 10;
    background-color: white;
    transform: translateY(100%);
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
}
   
    
}
.listMenu{
    @apply relative flex justify-between whitespace-nowrap border-l-2 border-transparent  py-2 px-4 text-sm text-gleads-greys-700 hover:border-gleads-buttonBlue-600 hover:bg-gleads-buttonBlue-100
}
.list_sub_menu{
    @apply block whitespace-nowrap border-l-2  border-transparent py-2 px-4 text-sm  hover:border-gleads-buttonBlue-600 hover:bg-gleads-buttonBlue-100
}