.animate-balloon {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}
.animate-balloons-X {
  animation-name: balloons-X;
}
.animate-balloons-Y {
  animation-name: balloons-Y;
}
@media (min-width: 1024px) {
  .animate-balloons-X-mobile {
    animation-name: balloons-X;
  }
  .animate-balloons-Y-mobile {
    animation-name: balloons-Y;
  }
}

@keyframes balloons-X {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25px);
  }
}
@keyframes balloons-Y {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
}
