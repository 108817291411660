// .sliderCustom {
//   div[class="slick-track"] {
//     div[class^="slick-slide"] {
//       > div {
//         @apply sm:px-4;
//       }
//     }
//   }
// }
.containerWrapper {
  @apply container px-8 lg:mx-[calc((100%-900px)/2)] lg:w-auto lg:max-w-none lg:px-0 xl:mx-[calc((100%-1160px)/2)];
}

.sliderCustom {
  div[class="slick-list"] {
    @apply -mx-8 md:mx-0;
    div[class="slick-track"] {
      @apply mx-auto flex overflow-visible;
      div[class^="slick-slide"] {
        @apply h-auto pt-6 pb-10 lg:py-10;
        > div {
          @apply mx-4 h-full shadow-none transition-shadow duration-200 ease-in-out lg:mx-[15px];
        }
        &[class~="slick-active"] {
          > div {
            @apply shadow-[0px_16px_40px_rgba(181,181,181,0.2)];
          }
        }
      }
    }
  }
}
